import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import References from "../components/sections/references"
import TextImageHero from "../components/sections/text-image-hero"
import TextImage from "../components/sections/text-image"
import Seo from "../components/seo"
import useModal from "../components/use-modal"
import CTA from "../components/elements/cta-button"
import DualSplit from "../components/sections/dual-split"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

const AProposDeSOBPage = () => {

  const [toggle, ContactModal] = useModal()

  const { t } = useTranslation('page_about')

  const tMeta = t('meta')
  const tHero = t('hero')
  const tValueProposition = t('value_proposition')
  const tReferences = t('references')
  const tStory1 = t('story_1')
  const tStory2 = t('story_2')

  return (
    <Layout>
      <Seo
        title={tMeta.title}
        description={tMeta.description}
      />
      <ContactModal />
      <TextImageHero
        title={tHero.title}
        subtitle={tHero.subtitle}
        cta={tHero.cta}
        toggle={toggle}
        picture="sob"
        imageAlt={tHero.image_alt}
      />
      <section className="section">
        <div className="container has-text-centered is-medium">
          <div className="columns is-centered is-multiline">
            <div className="column is-10">
              <h2 className="title is-3 is-uppercase mb-4">
                {tValueProposition.title}
              </h2>
            </div>
            <div className="column is-7">
              <p className="mb-3">
                <Trans
                  i18nKey={tValueProposition.p1}
                  components={{ b: <b /> }}
                />
              </p>
              <p className="mb-3">
                <Trans
                  i18nKey={tValueProposition.p2}
                  components={{ b: <b /> }}
                />
              </p>
              <CTA to="/">
                {tValueProposition.cta}
              </CTA>
            </div>
          </div>
        </div>
      </section>
      <References
        title={tReferences.title}
      />
      <TextImage
        title={tStory1.title}
        picture="pros"
        imageAlt={tStory1.image_alt}
      >
        <>
          <p className="mb-3">
            <Trans
              i18nKey={tStory1.p1}
              components={{ b: <b /> }}
            />
          </p>
          <p className="mb-3">
            <Trans
              i18nKey={tStory1.p2}
              components={{ b: <b /> }}
            />
          </p>
          <p className="mb-3">
            <Trans
              i18nKey={tStory1.p3}
              components={{ b: <b /> }}
            />
          </p>
          <CTA onClick={toggle}>
            {tStory1.cta}
          </CTA>

        </>
      </TextImage>
      <TextImage
        title={tStory2.title}
        alternate
        picture="alpi"
        imageAlt={tStory2.image_alt}
      >
        <>
          <p className="mb-3">
            <Trans
              i18nKey={tStory2.p1}
              components={{ b: <b /> }}
            />
          </p>
          <p className="mb-3">
            <Trans
              i18nKey={tStory2.p2}
              components={{ b: <b /> }}
            />
          </p>
          <p className="mb-3">
            <Trans
              i18nKey={tStory2.p3}
              components={{ b: <b /> }}
            />
          </p>
          <CTA onClick={toggle}>
            {tStory2.cta}
          </CTA>
        </>
      </TextImage>
      <DualSplit className="has-background-dark" />

    </Layout>
  )
}
export default AProposDeSOBPage


export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;